import store from '@/store';
import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators';
import { getAdminConfig, setAdminConfig, removeAdminConfig } from '@/utils/cookies';

@Module({ dynamic: true, store, name: 'appConfig' })
class appConfig extends VuexModule {
    // 首页基础信息
    public appConfig: any = getAdminConfig() || {};

    @Mutation
    private SET_APP_CONFIG(data: object) {
        this.appConfig = data;
        setAdminConfig(data);
    }

    @Action
    public setAppConfig() {
        // const url = process.env.NODE_ENV == 'development' ? 'http://192.168.1.42:9001/config.json' : '/config.json';
        // const url = process.env.NODE_ENV === 'development' ? 'https://test.feimajy.com/config.json' : '/config.json';
        const url = process.env.NODE_ENV === 'development' ? '/config.json' : '/config.json';
        return new Promise((resolve) => {
            fetch(url, {
                headers: {
                    'Cache-Control': 'no-cache'
                }
            }).then(response => response.json()).then(data => {
                this.SET_APP_CONFIG(data);
                resolve(data);
            }).catch(() => {
                removeAdminConfig();
            });
        });
    }

}
export const appConfigModule = getModule(appConfig);

/*
appConfig 参数
"vueShopName": "臺集運", // 店铺名称
"vueAdmin": "https://test.feimajy.com", // 跳转到admin 地址
"vueSrc": "https://test.feimajy.com/images/3", // 访问的地址图片
"vueApi": "https://testapi.paijiangjy.com", // api请求地址
"vueHkApi": "https://gatewayhk.paijiangjy.com", // api请求地址
"vueYm": "https://test.feimajy.com", // 返回主页面域名
"lineUrl": "https://test.feimajy.com/H5", // h5 第三方登录域名
"wxUrl": "https://test.feimajy.com/H5", // h5 第三方登录域名
"shopId": 3 // 店铺id
*/
